<template lang="pug">
  CanvasModal.brand-tab-color__edit-modal(
    icon="icon-color"
    title="Edit Team Brand Color"
    :max-width="700"
    :is-show="true"
    :is-grey="true"
    @closeOverlay="setShowBrandTabColorEditModal(false)"
  )
    ColorPalette(
      v-for="(item, index) in getActiveTeamColors"
      :key="index"
      :colors="item.colors"
      :name="item.name"
      :index="index"
      :is-edit-modal="true"
    )
    BaseButton.modal__add-palette-button(
      v-if="!isMaximumPalette"
      icon-before="icon-plus"
      :is-hollow="true"
      @click="addTeamPalette"
    ) Add new palette
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { maxPalette } from '@/assets/scripts/variables';

import ColorPalette from './ColorPalette.vue';

export default {
  name: 'BrandTabColorEditModal',
  components: {
    ColorPalette,
  },
  computed: {
    ...mapGetters('userData', ['isFreeUser']),
    ...mapGetters('team', ['getActiveTeamColors', 'isInTeamWorkspace']),
    isMaximumPalette() {
      const paletteLength = Object.values(this.getActiveTeamColors).length;
      let isMaximum = false;

      if (this.isInTeamWorkspace) {
        isMaximum = false;
      } else if (this.isFreeUser && paletteLength > maxPalette.free) {
        isMaximum = true;
      } else if (!this.isFreeUser && paletteLength > maxPalette.premium) {
        isMaximum = true;
      }

      return isMaximum;
    },
  },
  methods: {
    ...mapActions('team', ['addTeamPalette']),
    ...mapMutations(['setShowBrandTabColorEditModal']),
  },
};
</script>

<style lang="scss">
.brand-tab-color__edit-modal {
  z-index: 90; // make room for basedialog to show up

  .icon-color {
    color: $darkGrey800 !important;
  }

  .modal__add-palette-button {
    font-size: 0.875rem;
    padding: 15px 25px;
    color: var(--darkgrey900-white);

    &:hover {
      color: $darkGrey900;
    }


    .btn__content {
      display: flex;
      align-items: center;
    }

    i {
      margin-right: 12px;
      font-size: 1rem;
    }
  }

  .canvas-modal__header {
    background: var(--lightgrey-darkgrey900) !important;
    color: var(--darkgrey800-white) !important;

    .icon {
      color: var(--blue700-white) !important;
    }
  }

  .canvas-modal__content {
    background: var(--white-darkgrey700);

    .canvas-modal__closebtn {
      color: var(--darkgrey800-white);
    }

    .content {
      background: var(--white-darkgrey700);
      min-height: 500px;
    }
  }

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba($darkGrey600, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--lightgrey500-black800);
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--lightgrey500-black800); // so styling from Project.vue for scrollbar hover not applying
    }
  }
}
</style>
